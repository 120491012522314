body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

:root {
  --primary: #0684cd;
  --white: #ffffff;
  --unnamed-color: #666666;
  --concrete: #f3f3f3;
  --black: #0c0c0c;
  --mercury: #e1e1e1;
  --cod-gray: #1d1d1d;
  --box-shadow: #00000029;
  --solitude: #e3f0ff;
  --silver-chalice: #a5a5a5;
  --dusty-gray: #9c9c9c;
  --salem: #097148;
  --guardsman-red: #c40000;
  --elm: #226f85;
  --pattens-blue: #dff8ff;
  --alabaster: #f9f9f9;
  --font-family-poppins: "poppins";
  --alto: #cfcfcf;
  --monza: #cd0606;
  --light-pattens-blue: #d6e9ff;
  --narvik: #e6f7f0;
  --dash-border: #afafaf;
  --egg-sour: #fff3de;
  --orange-peel: #f79e00;
  --pippin: #ffdede;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url("../src/assets/image/Mask\ Group\ 10.png");
  background-size: cover;
  overflow: hidden;
}

.logImgMain {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-from-container {
  max-width: 70%;
  width: 100%;
  height: 673px;
  background-color: "red";
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.menu {
  display: none;
  gap: 5px;
  flex-direction: column;
}

.menu-line {
  width: 25px;
  height: 3px;
  background-color: var(--black);
}

.login-title {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 40px;
  font-family: var(--font-family-poppins);
}

.send-email {
  margin: 0;
  font-size: 18px;
  margin-bottom: 32px;
  font-family: var(--font-family-poppins);
}

.otp-title {
  font-family: var(--font-family-poppins);
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.popupContain {
  z-index: 2;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
}

.formContainMain {
  background-color: var(--white);
  width: 100%;
  /* padding: 45px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-md {
  /* text-align: center; */
  margin: 0px auto 0px auto;
}

.errorMassage {
  color: var(--guardsman-red);
  margin-bottom: 10px;
}

.form-md .form-group {
  /* margin-left: auto;
  margin-right: auto; */
  max-width: 350px;
  max-height: 60px;
}

.form-group {
  margin-bottom: 30px;
  position: relative;
}

.resend-otp {
  font-family: var(--font-family-poppins);
  font-size: 18px;
  margin: 0;
}

.resend-otp span {
  color: var(--primary);
  text-decoration: underline;
}

.form-control {
  position: relative;
  display: block;
  /* width: 100%; */
  border: 1px solid var(--unnamed-color);
  border-radius: 8px;
  background-color: transparent;
  margin: 0px auto;
  padding: 6px 4px 4px 14px;
  /* height: 40px; */
  min-width: 330px;
  height: 46px;
  outline: none !important;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.2s ease-in-out;
}

.form-label {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-family: var(--font-family-poppins);
  font-weight: 400;
  background: var(--white);
  color: var(--unnamed-color);
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}

.form-control:hover,
.form-control:focus {
  border: 1px solid var(--primary);
}

.inputError {
  border: 1px solid var(--guardsman-red) !important;
}

.textError {
  color: var(--guardsman-red) !important;
}

.labelFloating {
  top: -6px;
  color: var(--primary);
  font-size: 14px;
  font-family: var(--font-family-poppins);
}

.form-control:focus + label {
  top: -6px;
  color: var(--primary);
  font-size: 14px;
  font-family: var(--font-family-poppins);
  /* font-weight: bold; */
}

.btn-login {
  min-width: 350px;
  height: 56px;
  background-color: var(--primary);
  color: var(--white);
  border: unset;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
  margin-top: 48px;
  /* margin: 48px auto auto auto; */
  font-size: 16px;
  font-family: var(--font-family-poppins);
  /* opacity: 0.6; */
  cursor: pointer;
}

.otpContainer {
  /* margin: 5% auto; */
  display: flex;
  flex-direction: row;
  gap: 17px;
}

.otpInput {
  width: 75px;
  height: 56px;
  /* margin: 0 1rem; */
  border: 1px solid var(--concrete);
  font-size: 18px;
  font-family: var(--font-family-poppins);
  text-align: center;
  border-radius: 8px;
  color: var(--primary);
  background-color: var(--concrete);
  padding: 0;
}

.otpInput:hover,
.otpInput:focus,
.otpInput:focus-visible {
  outline: none;
  border: 1px solid var(--primary);
}

.btn-verify {
  min-width: 350px;
  height: 56px;
  background-color: var(--primary);
  color: var(--white);
  border: unset;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
  font-size: 16px;
  font-family: var(--font-family-poppins);
  opacity: 0.6;
  margin-top: 14px;
  margin-bottom: 16px;
}

.main-sider {
  background-color: var(--white) !important;
  min-width: 360px !important;
  border: 1px solid var(--mercury);
  border-radius: 0px 40px 40px 0px;
  height: 100vh;
}

.side-bar-image {
  padding-top: 12px;
  display: flex;
  justify-content: center;
}

.layout-contain .ant-layout {
  overflow-y: scroll;
  height: 100vh;
}

.layout-contain {
  background: var(--alabaster);
}

.close-collapsed {
  min-width: 112px !important;
}

.menu-content {
  position: relative;
  padding: 20px;
}

.menu-content .ant-menu-item-selected {
  background-color: var(--primary) !important;
}

.menu-content .ant-menu-item-selected .ant-menu-title-content {
  color: var(--white) !important;
}

.menu-content .ant-menu-item {
  padding: 15px auto 15px 24px !important;
  border-radius: 10px;
  height: 56px;
}

.menu-content .ant-menu-item .ant-menu-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.menu-content .ant-menu-item .ant-menu-item-icon g path {
  fill: var(--black);
}

.menu-content .ant-menu-item-selected .ant-menu-item-icon g path {
  fill: var(--white);
}

.action-icons div svg g path {
  fill: var(--alto);
}

.action-icons div svg:hover g path {
  fill: var(--primary);
}

.action-icons .delete-icon svg:hover g path {
  fill: var(--monza);
}

.menu-content .ant-menu-item .ant-menu-title-content {
  margin-inline-start: 16px !important;
}

.collapsed-icon {
  position: absolute;
  right: -6px;
  top: 25px;

  width: 31px;
  height: 31px;
}

.close-collapsed .ant-menu-item {
  width: 72px;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none !important;
}

.side-active {
  display: none;
}

.responsive-sidebar {
  background-color: var(--white);
  height: 100vh;
  width: 70%;
  position: relative;
}

.menu-content .ant-menu-item .ant-menu-title-content {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--black);
  font-family: var(--font-family-poppins);
}

.logout-menu {
  cursor: pointer;
  border-top: 1px solid var(--mercury);
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--black);
  font-family: var(--font-family-poppins);
  margin: 0px 20px 27px 20px !important;
  border-radius: 10px;
  height: 56px;
}

.logout-btn {
  padding: 18px 0px 0px 27px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.main-header {
  background-color: var(--alabaster);
  margin: 40px 0px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.main-layout-contain {
  padding: 0px 100px 0px 60px;
  background-color: var(--alabaster);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-notification {
  display: flex;
}

.user-info .user-icon {
  font-size: 20px;
  font-family: var(--font-family-poppins);
  color: var(--white);
  text-align: left;
  background: var(--primary);
  /* padding: 0px 20px; */
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-info .username {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.user-info .username span {
  font-family: var(--font-family-poppins);
  font-size: 18px;
  color: var(--black);
}

.title-dashboard {
  margin: 0px;
  font-size: 36px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
}

.table-switch-th .ant-switch-checked {
  background: var(--light-pattens-blue);
}

.table-switch-th .ant-switch-checked .ant-switch-handle::before {
  background: var(--primary);
}

.table-switch-th .ant-switch-checked:hover {
  background: var(--light-pattens-blue) !important;
}

/* Settings */
.title-setting {
  margin: 0px;
  font-size: 20px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
}

.settingsMain {
  width: 100%;
  /* height: 74vh; */
  border-radius: 10px;
  background-color: var(--white);
  padding: 30px 40px;
  margin-bottom: 80px;
}

.settingsMain span {
  font-family: var(--font-family-poppins);
}

.dropdown-main {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  font-family: var(--font-family-poppins);
  gap: 10px;
  /* padding-top:80px   ; */
}

.setting-border {
  border: 1px solid #E1E1E1;
  margin: 20px 0px;
  opacity: 1;
  /* margin-bottom: 20px; */
}

.fieldName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.fieldName input {
  border: 1px solid grey;
  border-radius: 5px;
  width: 66px;
  height: 41px;
  text-align: center;
}

.dropdown1 {
  width: 334px !important;
}

.border-y {
  width: 1px;
  background-color: #E1E1E1;
  height: 86px;
  margin: auto;
  opacity: 1;
}

.message-box {
  width: 100%;
}

.textarea textarea {
  margin-top: 10px;
  height: 200px;
  width:100%;
  border-radius: 5px;
}
.textarea:focus {
  border-color:#E1E1E1 ;
}

.searchBox {
  display: flex;
  justify-content: flex-end;
}

.submitBtn {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  margin:40px 0px;
  width: 400px;
  height: 60px;
  border-radius: 5px;
  background-color: #0684cd;
  color: var(--white);
  border: none;
  align-items: center;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  cursor: pointer;
}

/* .main-progress {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.progress-text {
  display: flex;
  justify-content: space-between;
}

.progress-text span {
  font-weight: 600;
  color: var(--black);
  font-size: 14px;
  text-transform: capitalize;
} */

.main-card {
  margin-top: 33px;
  background: var(--solitude);
  border-radius: 12px;
  padding: 24px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.main-card-text {
  display: flex;
  justify-content: space-between;
}

.card-text h1 {
  margin: 0;
  text-align: left;
  font-size: 36px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  letter-spacing: 1.8px;
  color: var(--black);
}

.card-text p {
  text-align: left;
  color: var(--black);
  margin: 0;
  letter-spacing: 0px;
  font-size: 18px;
  font-family: var(--font-family-poppins);
}

.recent-order {
  margin-top: 40px;
  background: var(--white);
  border: 0.5px solid var(--mercury);
  border-radius: 12px;
}

.order-title-contain {
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 30px; */
}

.ant-table-content {
  overflow: auto;
  width: 100%;
}

.order-title {
  color: var(--black);
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  font-family: var(--font-family-poppins);
  letter-spacing: 0px;
  flex-wrap: wrap;
  color: var(--black);
}

.order-filter span {
  background: var(--concrete);
  color: var(--silver-chalice);
  padding: 10px 24px;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  align-items: center;
  cursor: pointer;
}

.order-filter .active {
  border-radius: 5px;
  background: var(--primary);
  color: var(--white);
}

.table-layout .ant-table-container .ant-table-thead {
  background-color: var(--concrete);
}

.table-layout .ant-table-container .ant-table-thead tr .ant-table-cell {
  color: var(--dusty-gray);
  text-align: left;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  padding: 16px 10px;
}

.table-layout .ant-table-container .ant-table-thead tr td {
  padding: 0px;
}

.table-layout .ant-table-container .ant-table-tbody tr td {
  padding: 0px;
}

.table-layout .ant-table-container .ant-table-tbody .comman-table-row {
  border-radius: 24px;
}

.table-layout .ant-table-container .ant-table-tbody tr td {
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  padding: 16px 10px;
  color: var(--black) !important;
}

.table-layout .ant-table-container .ant-table-tbody .comman-table-row td {
  border-bottom: 1px solid var(--mercury);
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: var(--black) !important;
}

.order-pagination .ant-pagination-options .ant-pagination-options-size-changer {
  position: absolute;
  left: 96px;
  background: var(--white);
}

.order-pagination .ant-pagination-options::before {
  content: "View";
  position: absolute;
  left: 40px;
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  letter-spacing: 0px;
  color: var(--silver-chalice);
}

.order-pagination .ant-pagination-options::after {
  content: "entries per page";
  position: absolute;
  left: 171px;
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  letter-spacing: 0px;
  color: var(--silver-chalice);
}

.order-pagination
  .ant-pagination-options
  .ant-pagination-options-size-changer
  .ant-select-selector {
  border: 1px solid var(--silver-chalice);
  border-radius: 5px;
}

.order-pagination
  .ant-pagination-options
  .ant-pagination-options-size-changer:hover
  .ant-select-selector,
.order-pagination
  .ant-pagination-options
  .ant-pagination-options-size-changer:focus
  .ant-select-selector {
  border: 1px solid var(--silver-chalice) !important;
  outline: none;
}

.order-pagination
  .ant-pagination-options
  .ant-pagination-options-size-changer
  .ant-select-selector
  .ant-select-selection-item {
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: var(--black);
}

.order-pagination
  .ant-pagination-options
  .ant-pagination-options-size-changer
  .ant-select-arrow {
  color: var(--black);
}

.order-pagination .ant-pagination-total-text {
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  font-family: var(--font-family-poppins);
  color: var(--silver-chalice);
}

.order-pagination .ant-pagination-item-active {
  background-color: var(--primary);
}

.order-pagination .ant-pagination-item-active a:hover {
  color: var(--white);
}

.order-pagination .ant-pagination-item-active a {
  color: var(--white) !important;
}

.order-pagination .ant-pagination-item a {
  color: var(--black);
}

.order-pagination .ant-pagination-item {
  border-radius: 5px;
  min-width: 36px;
  min-height: 36px;
}

.amount-td {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.amount-td span {
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: var(--black);
}

.amount-td .status-paid {
  color: var(--salem);
}

.amount-td .status-unpaid {
  color: var(--guardsman-red);
}

.order-status-td {
  font-size: 18px;
  max-width: 130px;
  min-height: 41px;
  font-family: var(--font-family-poppins);
  /* color: var(--elm);
  background-color: var(--pattens-blue); */
  border-radius: 4px;
  padding: 8px;
  text-align: center;
}

.order-new {
  background-color: var(--egg-sour);
  color: var(--orange-peel);
}

.order-proposed {
  background-color: var(--pattens-blue);
  color: var(--elm);
}

.order-prepared {
  background-color: var(--pippin);
  color: var(--guardsman-red);
}

.order-completed {
  background-color: var(--narvik);
  color: var(--salem);
}

.action-icons {
  display: flex;
  justify-content: center;
  gap: 10;
}

.action-icons div {
  cursor: pointer;
}

.recent-table {
  position: relative;
}

.title-business {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-family-poppins);
  color: var(--black);
  margin: 0;
}

.table-title {
  margin: 0px;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--black);
  font-family: var(--font-family-poppins);
}

.table-search-input {
  min-height: 44px;
  max-width: 445px;
  border: 1px solid var(--silver-chalice);
  border-radius: 5px;
}

.user-business-info {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  row-gap: 10px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.user-field {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  flex-wrap: wrap;
}

.user-field-new {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  flex-wrap: wrap;
  margin-left: 40px;
}

.order-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  row-gap: 0px;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  flex-wrap: wrap;
}

.user-field-name {
  padding-right: 16px;
}

.order-date-time {
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: var(--silver-chalice);
}

/* //check below */
.description {
  margin-right: 10px;
}

.user-details-card {
  display: flex;
  flex-direction: column;
  /* gap: 43px; */
  padding: 24px 40px;
  background-color: var(--white);
  border: 1px solid var(--mercury);
  border-radius: 8px;
}

.date-ro-time {
  display: flex;
  align-items: center;
  column-gap: 24px;
  flex-wrap: wrap;
}

.order-date {
  display: flex;
  align-items: center;
  gap: 8px;
}

.delivery-information-title {
  color: var(--black);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  font-family: var(--font-family-poppins);
  opacity: 1;
}

.order-title {
  display: flex;
  column-gap: 40px;
  align-items: center;
}

.order-title h1 {
  color: var(--black);
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  font-family: var(--font-family-poppins);
}

.order-details-status {
  width: 116px;
  height: 41px;
  background: var(--narvik);
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;
  font-size: 18px;
  color: var(--salem);
}

.delivery-information-card {
  display: flex;
  flex-direction: column;
  background: var(--white);
  gap: 24px;
  border: 1px solid var(--mercury);
  border-radius: 8px;
  padding: 35px 40px;
}

.delivery-information {
  display: flex;
  column-gap: 50px;
  row-gap: 10px;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}

.order-details-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  border: 1px solid var(--mercury);
  border-radius: 8px;
  flex-wrap: wrap;
  padding: 26px 40px;
  gap: 10px;
}

.user-details-title {
  font-family: var(--font-family-poppins);
  font-size: 24px;
  color: var(--black);
  font-weight: 600;
  border-bottom: 1px solid var(--dusty-gray);
  padding-bottom: 18.5px;
}

.order-status-label {
  color: var(--black);
  font-size: 18px;
  letter-spacing: 0;
  font-family: var(--font-family-poppins);
}

.breadcrumb-main {
  padding-bottom: 40px;
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  color: var(--silver-chalice);
  display: flex;
  gap: 8px;
}

.breadcrumb-main ol li:last-child {
  color: var(--primary);
}

.breadcrumb-main ol {
  align-items: center;
}

.breadcrumb-main ol li {
  cursor: pointer;
}

.breadcrumb-main ol .ant-breadcrumb-separator {
  align-items: center;
  display: flex;
}

.header-logout-menu {
  cursor: pointer;
  position: absolute;
  right: 43px;
  top: 65px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 15px 66px;
  background: var(--white);
  border: 1px solid var(--primary);
  border-radius: 4px;
  line-height: 0px;
}

.header-logout-menu div {
  font-family: var(--font-family-poppins);
  color: var(--black);
  font-size: 18px;
  letter-spacing: 0px;
}

.main-customer-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item-name-td {
  display: flex;
  gap: 16px;
  align-items: center;
}

.item-name-td div {
  font-family: var(--font-family-poppins);
  color: var(--black);
  text-align: left;
  font-size: 18px;
}

.payment-status-td {
  font-size: 18px;
  font-family: var(--font-family-poppins);
  letter-spacing: 0px;
  color: var(--salem);
  padding: 5px 20px;
  border: 1px solid var(--salem);
  border-radius: 4px;
  width: fit-content;
}

.payment-status-td p {
  margin: 0;
  padding: 0;
  width: fit-content;
}

.collapse-open {
  transform: rotate(180deg);
}

.order-type-td {
  max-width: 114px;
  min-height: 41px;
  font-family: var(--font-family-poppins);
  background: var(--narvik);
  border-radius: 4px;
  font-size: 18px;
  padding: 8px 18px;
  color: var(--salem);
}

.order-total-contain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
  margin-left: auto;
  margin-right: 60px;
}

.nudgeStatus {
  border: none;
  border-radius: 4px;
  background-color: #e6f7f0;
  height: 41px;
  line-height: 40px;
  color: #097148;
}

.nudgeStatusExpired {
  border: none;
  border-radius: 4px;
  background-color: #ffdede;
  height: 41px;
  line-height: 40px;
  color: #c40000;
}

.order-total-flex {
  display: flex;
  /* gap: 400px; */
  align-items: center;
  color: var(--black);
  text-align: left;
  font-size: 18px;
  justify-content: space-between;
  font-family: var(--font-family-poppins);
}

.lable-name {
  min-width: 350px;
}

.order-total-amount {
  display: flex;
  /* gap: 400px; */
  align-items: center;
  color: var(--black);
  text-align: left;
  font-size: 18px;
  font-family: var(--font-family-poppins);
  font-weight: bold;
  justify-content: space-between;
}

.order-total-border {
  border-bottom: 1px dashed var(--dash-border);
}

.loaderBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--dusty-gray);
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@media only screen and (max-width: 1200px) {
  .collapsed-icon {
    display: none;
  }

  .auth-from-container {
    max-width: 80%;
    height: 500px;
  }

  .formContainMain {
    padding: 0px 20px;
  }

  .main-layout-contain {
    padding: 0px 20px;
  }

  /* .user-business-info {
   gap: 40px;
  } */
  .order-total-contain {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-left: auto;
  }

  .order-total-border {
    width: 100%;
    max-width: unset;
  }

  .lable-name {
    min-width: unset !important;
  }
}

@media only screen and (max-width: 992px) {
  .side-active {
    display: block !important;
  }

  .roundShap {
    height: 300px;
    width: 300px;
  }

  .auth-from-container {
    max-width: 90%;
    height: 500px;
  }

  .formContainMain {
    padding: 0px 20px;
  }

  .user-business-info {
    flex-wrap: wrap;
    justify-content: unset;
    gap: 10px;
  }

  .formContainMain {
    padding: 20px;
  }

  .main-sider {
    display: none;
  }

  .main-header {
    margin: 0px -20px;
    background-color: var(--white);
    padding: 0px 20px;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  }

  .main-layout-contain {
    padding-top: 100px;
  }

  .menu {
    display: flex;
  }

  .order-pagination .ant-pagination-options::before {
    content: unset;
  }

  .order-pagination .ant-pagination-options::after {
    content: unset;
  }

  .order-pagination
    .ant-pagination-options
    .ant-pagination-options-size-changer {
    position: sticky;
    margin-right: 10px;
  }

  .form-control {
    min-width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 768px) {
  .side-active {
    display: block !important;
  }

  .logImgMain {
    display: none;
  }

  .auth-from-container {
    max-width: min-content;
  }

  .formContainMain {
    min-width: 350px;
    padding: 30px;
  }

  .order-title-contain {
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .auth-from-container {
    height: auto;
  }

  .main-card {
    min-height: unset;
  }
}

@media only screen and (max-width: 458px) {
  .responsive-sidebar {
    width: 90%;
  }

  .formContainMain {
    /* min-width: 350px; */
    padding: 10px;
  }
}

@media only screen and (max-width: 375px) {
  /* .formContainMain {
    width: 270px;
    padding: 10px;
    min-width: unset;
  } */
}
